import { render, staticRenderFns } from "./HeaderSearch.vue?vue&type=template&id=22e025b9&scoped=true&"
import script from "./HeaderSearch.vue?vue&type=script&lang=js&"
export * from "./HeaderSearch.vue?vue&type=script&lang=js&"
import style0 from "./HeaderSearch.vue?vue&type=style&index=0&id=22e025b9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22e025b9",
  null
  
)


    import installComponents from "!D:/IIS/rentmagic.website.vue.vlaams-brabant/test/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BFormInput, BButton, BForm} from 'bootstrap-vue'
    installComponents(component, {BFormInput, BButton, BForm})
    

export default component.exports