import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import { getCart } from '../services/CartService'

const vuexPersist = new VuexPersist({
  key: 'rentmagic-frontend',
  storage: window.localStorage
})

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state: {
    user: {},
    role: '',
    bearerToken: '',
    DateTimeBusinessStart: '',
    DateTimeBusinessEnd: '',
    retrievalTime: '',
    returnTime: '',
    cartCount: 0,
    cart: [],
    cartCache: null,
    contactID: null,
    accounts: [],
    account: {},
    customerID: null,
    reservation: {},
    reservations: null,
    itemStore: {}
  },
  mutations: {
    setUser(state, { user, contactID }) {
      state.user = user
      state.contactID = contactID
      state.accounts = state.user.Accounts.Collection
      state.account = state.accounts.find(
        account => account.ContactID == state.contactID
      )
      state.customerID = state.account.CustomerID
      state.role = state.user.RoleName
    },
    setAccounts(state, { accounts }) {
      state.accounts = accounts
      state.account = state.accounts.find(
        account => account.UserEmail == state.user.UserEmail
      )
    },
    addItemStoreItem(state, { item, key }) {
      state.itemStore[key] = item
    },
    resetStoreItem(state, { key }) {
      delete state.itemStore[key]
    },
    resetStoreItems(state) {
      state.itemStore = {}
    },
    setContactID(state, { contactID }) {
      state.contactID = contactID
      state.account = state.accounts.find(
        account => account.ContactID == state.contactID
      )
      state.customerID = state.account.CustomerID
      getCart()
    },
    unsetUser(state) {
      state.user = {}
      state.bearerToken = null
      state.contactID = null
      state.accounts = []
      state.account = null
      state.reservations = null
      state.subUsers = []
      state.customerID = null
      state.customeriD = null
      state.role = null
      state.retrievalTime = ''
      state.returnTime = ''
    },
    setLatestReservation(state, { reservation }) {
      state.reservation = reservation
    },
    setCart(state, { cart }) {
      state.cartCache = cart
      state.cartCount = cart.CartItems.Collection.length
    },

    unsetRetrievalTime(state) {
      state.retrievalTime = ''
    },
    setRetrievalTime(state, { retrievalTime }) {
      state.retrievalTime = retrievalTime
    },
    setReturnTime(state, { returnTime }) {
      state.returnTime = returnTime
    },
    unsetReturnTime(state) {
      state.returnTime = ''
    },
    unsetCart(state) {
      state.cart = []
      state.cartCount = 0
    },
    setReservations(state, { reservations }) {
      state.reservations = reservations
    },
    setBearerToken(state, { token }) {
      state.bearerToken = token
    },
    unsetBearerToken(state) {
      state.bearerToken = ''
    },
    setDateTimeBusinessStart(state, payload) {
      state.DateTimeBusinessStart = payload.date
    },
    unsetDateTimeBusinessStart(state) {
      state.DateTimeBusinessStart = ''
    },
    setDateTimeBusinessEnd(state, payload) {
      state.DateTimeBusinessEnd = payload.date
    },
    unsetDateTimeBusinessEnd(state) {
      state.DateTimeBusinessEnd = ''
    }
  },
  getters: {
    cartCache: state => state.cartCache,
    user: state => state.user,
    token: state => state.bearerToken,
    cartCount: state => state.cartCount,
    DateTimeBusinessStart: state => state.DateTimeBusinessStart,
    DateTimeBusinessEnd: state => state.DateTimeBusinessEnd,
    returnTime: state => state.returnTime,
    retrievalTime: state => state.retrievalTime,
    contactID: state => state.contactID,
    accounts: state => state.accounts,
    account: state => state.account,
    reservation: state => state.reservation,
    reservations: state => state.reservations,
    role: state => state.role,
    customerID: state => state.customerID,
    getItemStoreItem: state => key => {
      return state.itemStore[key]
    }
  },
  plugins: [vuexPersist.plugin]
})
